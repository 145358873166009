<template>
  <div
    v-show="value"
    ref="popup"
    :style="clipPath"
    class="smart-popup"
  >
    <div class="pop-up-title my-2 pl-14">
      <slot name="title" />
    </div>
    <slot name="close-button">
      <v-btn
        icon
        @click="closePopup()"
        class="close-button"
      >
        <v-icon
          x-large
          color="white"
        >{{$icon('i.CloseCircle')}}</v-icon>
      </v-btn>
    </slot>
    <div
      :style="contentMargin"
      class="smart-popup-content"
    >
      <slot name="default" />
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      clipPath: {},
      contentMargin: {},
      observers: []
    }
  },
  methods: {
    closePopup () {
      this.$emit('input', false)
    },
    closePopupOnEscape (e) {
      if (e.code === 'Escape') {
        this.closePopup()
      }
    },
    disconnect () {
      this.observers.forEach(observer => observer.disconnect())
    },
    generateCircle (e, popUp) {
      const r = e.rect.width / 2
      return `H${e.rect.left} V${e.rect.top + r} a${r},${r} 0 1,1 ${2 * r},0 a${r},${r} 0 1,1 -${2 * r},0 V${popUp.bottom}`
    },
    generateClipPath () {
      const elements = this.preserveElements().map(p => Object.assign({}, p, { rect: p.element?.getBoundingClientRect() })).filter(e => e.rect)
      const margins = [0, 0, 0, 0]
      const documentRect = document.body.getBoundingClientRect()
      const popUp = this.$refs.popup.getBoundingClientRect()
      let path = `M0 0 V${popUp.bottom}`
      elements.forEach(e => {
        if (e.pushContent) {
          if (e.rect.width > e.rect.height) {
            if (e.rect.top > e.rect.bottom) {
              margins[2] = Math.max(margins[2], documentRect.bottom - e.rect.top)
            } else {
              margins[0] = Math.max(margins[0], e.rect.bottom)
            }
          } else {
            if (e.rect.left > e.rect.right) {
              margins[3] = Math.max(margins[3], e.rect.right)
            } else {
              margins[1] = Math.max(margins[1], documentRect.right - e.rect.left)
            }
          }
        }
        path += e.isCircle ? this.generateCircle(e, popUp) : this.generateRectangle(e, popUp)
      })
      this.clipPath = Object.assign({}, { 'clip-path': `path('${path} H${popUp.right} V0 Z')` })
      this.contentMargin = Object.assign({}, { margin: `${margins[0]}px ${margins[1]}px ${margins[2]}px ${margins[3]}px` })
    },
    generateRectangle (e, popUp) {
      return ` H${e.rect.left} V${e.rect.top} H${e.rect.right} V${e.rect.bottom} H${e.rect.left} V${popUp.bottom}`
    },
    observeElements () {
      const popupResizeObserver = new ResizeObserver(this.generateClipPath)
      popupResizeObserver.observe(this.$refs.popup)

      const appMutationObserver = new MutationObserver(this.generateClipPath)
      appMutationObserver.observe(this.$root.$el, { childList: true, attributes: true, subtree: true })

      this.observers = [popupResizeObserver, appMutationObserver]
    }
  },
  beforeDestroy () {
    this.observers.forEach(observer => observer.disconnect())
  },
  watch: {
    async value (v) {
      if (v) {
        await this.$waitFor(() => this.$refs?.popup)
        this.observeElements()
        this.generateClipPath()
        document.body.addEventListener('keyup', this.closePopupOnEscape)
      } else {
        document.body.removeEventListener('keyup', this.closePopupOnEscape)
        this.disconnect()
      }
    }
  },
  props: {
    preserveElements: {
      type: Function,
      default: () => []
    },
    value: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.close-button
  position fixed
  top 12px
  right 20px

.pop-up-title
  position absolute

.smart-popup
  background-color rgba(33, 33, 33, 0.85)
  position fixed
  top 0
  left 0
  z-index 8
  width 100%
  height 100%
  display flex

.smart-popup-content
  height 100%
  width 100%
  padding 65px 54px
  display flex
</style>
